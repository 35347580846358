/// <reference path="/dist/scripts/!shared/angular/angular-bundle.js"
/// <reference path="/dist/scripts/!shared/jquery/jquery-bundle.js"

(function (app) {
    'use strict';
    var itemsPerPage = 10;

    app.run(['$rootScope', function ($rootScope) {
    }]);

    app.config(['$locationProvider', '$httpProvider', function ($locationProvider, $httpProvider) {
        // to have the correct relative $location.path()
        $locationProvider.html5Mode(true);
        // to have a "correct" AJAX request when $http.get()
        $httpProvider.defaults.headers.common["X-Requested-With"] = 'XMLHttpRequest';
    }]);

    app.filter('trustAsResourceUrl', ['$sce', function ($sce) {
        return function (val) {
            return $sce.trustAsResourceUrl(val);
        };
    }]);

    app.service('helper', [function () {
        return {
        };
    }]);

    app.filter('html', ['$sce', function ($sce) {
        return function (val) {
            return $sce.trustAsHtml(val);
        };
    }]);

    app.service("itemList", ['$location', '$http', '$httpParamSerializer',
        function ($location, $http, $httpParamSerializer) {
            this.lastItemSent = false;
            var defaultSkip = 0, defaultTake = 10, imgWidth = 231, imgHeight = 111;

            var loadItems = function (skip, take) {
                if (typeof skip === 'undefined') skip = defaultSkip;
                if (typeof take === 'undefined') take = defaultTake;
                var query = $location.search().query;
                var params = { "ajax": "search", "skip": skip, "take": take, "query": query, "imgw": imgWidth, "imgh": imgHeight };
                var queryString = $httpParamSerializer(params);
                var newUrl = $location.path() + "?" + queryString;
                return $http.get(newUrl)
                    .then(function (response) {
                        var result = null;
                        if (response && response.data)
                            result = angular.fromJson(response.data);
                        return result;
                    });
            }

            var get = function (skip, take) {
                return loadItems(skip, take);
            };

            return {
                get: get
            };
        }]);

    app.controller("PaginationCtrl", ['$window', '$scope', '$timeout', '$uibModal', '$log', '$http', '$httpParamSerializer', 'itemList',
        function ($window, $scope, $timeout, $uibModal, $log, $http, $httpParamSerializer, itemList) {
            $scope.animationsEnabled = true;
            var items = itemList;
            $scope.count = 0;
            $scope.itemsPerPage = itemsPerPage;
            $scope.currentPage = 0;
            $scope.lastItemsSent = false;
            $scope.pagedItems = [];

            $scope.dotify = function () {
                // ellipsis title
                var ellipsis = $("article.searchResult h3");
                var nrLines = 1;
                var ellHeight = parseInt(ellipsis.css('line-height'), 10) * nrLines;
                ellipsis.dotdotdot({ height: ellHeight });

                // ellipsis body
                ellipsis = $("article.searchResult .text");
                nrLines = 4;
                ellHeight = parseInt(ellipsis.css('line-height'), 10) * nrLines;
                ellipsis.dotdotdot({ height: ellHeight });
            }

            $scope.open = function ($event, link, size) {
                $event.preventDefault();
                if (!link) {
                    link = $event.delegateTarget.href;
                }
                var params = { "ajax": "popup" };
                var queryString = $httpParamSerializer(params);
                link = link + "?" + queryString;

                var safeApply = function (scope, fn) {
                    (scope.$$phase || scope.$root.$$phase) ? fn() : scope.$apply(fn);
                };

                $http.get(link).then(function (response) {
                    var modalInstance = $uibModal.open({
                        animation: $scope.animationsEnabled,
                        templateUrl: 'newsModal.html',
                        controller: 'NewsModalInstanceCtrl',
                        size: size,
                        resolve: {
                            item: function () {
                                return response.data.item;
                            }
                        }
                    });

                    modalInstance.result.then(function (selectedItem) {
                        $scope.selected = selectedItem;
                    }, function () {
                        $log.info('Modal dismissed at: ' + new Date());
                    });
                }, function (response) {
                    safeApply($scope, function () {
                        var parser = document.createElement('a');
                        parser.href = link;
                        $window.location.href = parser.pathname;
                    });
                });
            }

            var readAnswer = function (response) {
                if (response) {
                    $timeout(function () {
                        $scope.pagedItems = $scope.pagedItems.concat(response.items);
                        $scope.count = response.count;
                        $scope.total = response.total;
                        $scope.query = response.query;
                        $scope.lastItemSent = response.lastItemSent;
                        $scope.$apply();
                        //$scope.dotify();
                        //$scope.$apply();
                    });
                }
            }
            // load first page
            items.get($scope.currentPage * $scope.itemsPerPage, $scope.itemsPerPage)
                .then(readAnswer);

            // load other pages
            $scope.loadMore = function () {
                $scope.currentPage++;
                items.get($scope.currentPage * $scope.itemsPerPage, $scope.itemsPerPage)
                    .then(readAnswer);
            };

            $scope.nextPageDisabledClass = function () {
                return $scope.lastItemSent ? "disabled" : "";
            };
        }]);

    app.controller('NewsModalInstanceCtrl', ['$scope', '$uibModalInstance', 'item',
        function ($scope, $uibModalInstance, item) {
            $scope.item = item;
            $scope.selected = {
                item: item
            };

            $scope.ok = function () {
                $uibModalInstance.close($scope.selected.item);
            };

            $scope.cancel = function () {
                $uibModalInstance.dismiss('cancel');
            };
        }]);

    app.controller("CarouselNewsItemCtrl", ['$scope',
        function ($scope) {
            $scope.myInterval = 5000;
            $scope.noWrapSlides = false;
        }]);

    app.directive("owlCarousel", function () {
        return {
            restrict: 'E',
            transclude: false,
            link: function (scope) {
                scope.initCarousel = function (element) {
                    console.log("popupCarousel");
                    setCarousel("#popupCarousel", 1, true, true);
                };
            }
        };
    })

    app.directive('owlCarouselItem', [function () {
        return {
            restrict: 'A',
            transclude: false,
            link: function (scope, element) {
                // wait for the last item in the ng-repeat then call init
                if (scope.$last) {
                    scope.initCarousel(element.parent());
                }
            }
        };
    }]);
})(angular.module('searchApp', ['ngSanitize', 'ngAnimate', 'ui.bootstrap']));

angular.bootstrap(document.getElementById('searchResults'), ['searchApp']);

/* TODO: if used, use as separate module */
angular.module('dotdotdot-angular', [])
    .directive('dotdotdot', function () {
        return {
            restrict: 'A',
            link: function (scope, element, attributes) {
                scope.$watch(function () {
                    element.dotdotdot({ watch: true });
                });
            }
        }
    });